<template>
  <div>
    <v-container>
      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-start" cols="12" sm="12" md="8" lg="8">
        </v-col>
        <v-col class="d-flex justify-center" cols="12" sm="12" md="8" lg="8">
          <div class="content" v-html="content"></div>
        </v-col>
        <v-col
          v-if="!content"
          class="d-flex flex-column justify-center align-center"
          cols="12"
          sm="12"
          md="8"
          lg="8"
        >
          <v-img src="../../assets/Pulse-0.7s-200px.svg" max-width="50"></v-img>
          <h5 class="pa-0">Carregando...</h5>
        </v-col>
      </v-row>
      <v-row v-if="link_form">
        <v-col>
          <h2 class="my-4">Pesquisa</h2>
          <iframe class="iframe_pesquisa" :src="link_form"></iframe>
        </v-col>
      </v-row>
      <v-row v-if="docs.length">
        <v-col>
          <h2 class="my-4">Arquivos</h2>
          <v-card
            @click="downloadFile(file.name, file.path)"
            outlined
            class="d-flex"
            v-for="(file, index) in docs"
            :key="index"
          >
            <v-icon class="pa-2 blue--text">mdi-download</v-icon>
            <v-card-text>{{ file.name }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-container class="d-flex justify-center align-center">
          <v-col
            class="d-flex justify-center pa-0 pt-10 pb-50"
            cols="12"
            sm="12"
            md="8"
            lg="8"
          >
            <v-btn color="success" class="text-decoration-none" @click="back"
              >Voltar</v-btn
            >
          </v-col>
        </v-container>
      </v-row>
    </v-container>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import newsService from "../../service/newsService";

export default {
  name: "Post",
  data() {
    return {
      content: "",
      docs: [],
      link_form: null,
    };
  },
  methods: {
    downloadFile(name, url) {
      const reportName = String(name);
      const type =
        /\.(csv|xls|xlsx|txt|pdf|xml|png|jpg|jpeg|gif|svg|doc|docx)$/i.exec(
          reportName
        )[1];
      const types = {
        pdf: "application/pdf",
        csv: "text/csv",
        xls: "application/vnd.ms-excel",
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        txt: "text/plain",
        xml: "application/xml",
      };
      const fileSplit = String(url).split("/");
      const file = fileSplit[fileSplit.length - 1];
      let fileData = {
        name: reportName,
        type: type,
        file: file,
      };
      this.overlay = true;
      newsService.downloadFile(fileData).then((res) => {
        let base64 = btoa(
          new Uint8Array(res.data[0].data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        let sampleArr = this.base64ToArrayBuffer(base64);
        this.saveByteArray(reportName, types[type], sampleArr);
        this.overlay = false;
      });
    },
    base64ToArrayBuffer(base64) {
      let binaryString = window.atob(base64);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(reportName, type, byte) {
      let blob = new Blob([byte], { type: type });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    },
    getPost() {
      const paramsRouter = this.$router.history.current.params;
      const id = parseInt(paramsRouter.id);

      newsService.get({ id: id }).then((res) => {
        const data = res.data[0];
        this.content = data.content;
        this.link_form = data.link_form;

        if (data.docs) {
          const docsParsed = JSON.parse(data.docs);
          this.docs.push(...docsParsed);
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
  components: {
    Footer: () => import("../../layouts/web/commons/Footer"),
  },
  mounted() {
    this.getPost();
  },
};
</script>

<style lang="sass" scoped>
.iframe_pesquisa
    border: none
    width: 100%
    min-height: 600px

.container
  text-align: justify !important

.box-sizing
  box-sizing: border-box
</style>

<style lang="sass">
.img-post
  max-width: 100%
  background-position: center
  position: relative
  min-width: 100%
  max-width: 100%
  background-position: center
</style>
